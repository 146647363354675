export function validateScriptContent(htmlSnippet) {
  // Regular expression to match <script> and <style> tags (both inline and external, and self-closing)
  const scriptStyleRegex =
    /<(script|style|noscript)\b[^>]*>([\s\S]*?)<\/\1>|<(script|style|noscript)\b[^>]*\/>/gi;
  // Regular expression to match HTML comments
  const commentRegex = /<!--[\s\S]*?-->/g;

  // Remove all HTML comments and any surrounding whitespace
  const snippetWithoutComments = htmlSnippet.replace(commentRegex, "").trim();

  // Find all <script> and <style> tags in the snippet without comments
  const matchedScriptsAndStyles =
    snippetWithoutComments.match(scriptStyleRegex);

  // If no <script> or <style> tags are found, it's invalid
  if (!matchedScriptsAndStyles) {
    return false;
  }

  // Replace all <script> and <style> tags with an empty string
  const snippetAfterRemovingScriptsAndStyles = snippetWithoutComments
    .replace(scriptStyleRegex, "")
    .trim();

  // Check if the cleaned snippet contains anything other than empty space (e.g., no text or tags outside script and style tags)
  if (snippetAfterRemovingScriptsAndStyles.length > 0) {
    return false;
  }

  // If everything matches, return true
  return true;
}
export function executeScriptsAndStyles(content, scriptId = "-") {
  return new Promise((resolve, reject) => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const scripts = doc.getElementsByTagName("script");
      const styles = doc.getElementsByTagName("style");

      let loadedElements = 0;
      const totalElements = scripts.length + styles.length;

      function loadScript(scriptElement, index) {
        return new Promise((resolveScript, rejectScript) => {
          const newScript = document.createElement("script");

          if (scriptElement.src) {
            newScript.src = scriptElement.src;
            newScript.onload = () => {
              console.log(
                `[${scriptId}] External script ${index + 1} loaded successfully:`,
                scriptElement.src,
              );
              resolveScript();
            };
            newScript.onerror = (error) => {
              console.error(
                `[${scriptId}] Error loading external script ${index + 1}:`,
                scriptElement.src,
                error,
              );
              rejectScript(error);
            };
          } else {
            newScript.textContent = scriptElement.textContent;
            console.log(`[${scriptId}] Inline script ${index + 1} executed`);
            resolveScript();
          }

          document.body.appendChild(newScript);
        });
      }

      function loadStyle(styleElement, index) {
        return new Promise((resolveStyle) => {
          const newStyle = document.createElement("style");
          newStyle.textContent = styleElement.textContent;
          document.head.appendChild(newStyle);
          console.log(`[${scriptId}] Style ${index + 1} added`);
          resolveStyle();
        });
      }

      let elementPromise = Promise.resolve();

      Array.from(scripts).forEach((script, index) => {
        elementPromise = elementPromise
          .then(() => loadScript(script, index))
          .then(() => {
            loadedElements++;
            console.log(
              `[${scriptId}] Progress: ${loadedElements}/${totalElements} elements loaded`,
            );
          })
          .catch((error) => {
            console.error(
              `[${scriptId}] Script ${index + 1} failed to load:`,
              error,
            );
            // Continue loading other elements even if one fails
          });
      });

      Array.from(styles).forEach((style, index) => {
        elementPromise = elementPromise
          .then(() => loadStyle(style, index))
          .then(() => {
            loadedElements++;
            console.log(
              `[${scriptId}] Progress: ${loadedElements}/${totalElements} elements loaded`,
            );
          })
          .catch((error) => {
            console.error(
              `[${scriptId}] Style ${index + 1} failed to load:`,
              error,
            );
            // Continue loading other elements even if one fails
          });
      });

      elementPromise.then(() => {
        if (loadedElements === totalElements) {
          resolve(
            `[${scriptId}] All ${totalElements} elements (scripts and styles) loaded successfully`,
          );
        } else {
          resolve(
            `[${scriptId}] ${loadedElements} out of ${totalElements} elements (scripts and styles) loaded successfully`,
          );
        }
      });
    } catch (error) {
      reject(
        new Error(
          `[${scriptId}] An error occurred while loading scripts and styles: ` +
            error.message,
        ),
      );
    }
  });
}
